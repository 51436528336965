/*
 * @component Marketplace 
 * @description Fija el ancho del contenido
 */
.marketplace-content{
    width: 100%;
}

/*
 * @component Marketplace
 * @description Establece un font-size para los textos en success 
 */
.marketplace-content > * {
    font-size: 1rem;
}

/*
 * @component Marketplace -> IconCheck
 * @description Establece el tamaño del icono 
 */
.marketplace-icon-mark{
    width: 4rem;
    height: 4rem;
}

/*
 * @component Marketplace  
 * @description Establece fondo y padding en el formulario de pasos
 */
.marketplace-form{
    background-color: #233B58 !important;
    padding: 3rem 4rem;
}

/*
 * @component Marketplace 
 * @description Establece el color blanco en los textos 
 */
.marketplace-form, .marketplace-form * {
    color: white !important;
}

.marketplace-steps{
    justify-content: center !important;
}

/*
 * @component Marketplace -> Steps 
 * @description Establce el largo de los textos en cada step
 */
.marketplace-steps .ant-steps-item-content{
    width: 120px !important;
    margin: 1rem 0 0 10px !important;
}
/*
 * @component Marketplace -> Steps
 * @description Establece el fondo blanco de las lineas conectoras entre los nodos de los pasos
 */
.marketplace-steps .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after{
    background-color: white;
}

/*
 * @component Marketplace -> CustomDot
 * @description Crea un dot personalizado para los steps
 */
.marketplace-dot{
    width: 35px;
    height: 35px;
    margin: -12px 0 0 -15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #233B58;
    border: 1px solid white;
    border-radius: 50%;
}

/*
 * @component Marketplace -> CustomDot 
 * @description Establecer un cambio de fondo para los dots activos
 */
.marketplace-dot.marketplace-dot-active{
    background-color: white;
}

/*
 * @component Marketplace -> CustomDot
 * @description Establcer estilos en los numeros del dot
 */
.marketplace-dot div{
    font-size: 1rem !important;
    font-weight: bold !important; 
    color: white !important;
    margin-bottom: 0 !important;
}

/*
 * @component Marketplace -> CustomDot
 * @description Establecer estilos en los numeros del dot cuando este activo
 */
.marketplace-dot.marketplace-dot-active div{
    color: #233B58 !important;
}

/*
 * @component Marketplace -> Input 
 * @description Establecer estilos generales para todos los inputs
 */
.marketplace-content .ant-input, .marketplace-content .ant-input-number-input{
    background-color: rgba(255, 255, 255, 0.2) !important;
    color: white !important;
}

.marketplace-content .ant-input-affix-wrapper {
    background-color: rgba(255, 255, 255, 0.2) !important;
}

.marketplace-content .ant-input-affix-wrapper .ant-input{
    background-color: transparent !important;
    color: white !important;
}

/*
 * @component Marketplace -> Input 
 * @description Establecer padding para todos los inputs
 */
.marketplace-content .ant-input-number-input{
    padding: 8px 11px !important;
}

/*
 * @component Marketplace -> InputNumber 
 * @description Hacer correciones en el largo, fondo y borde que vienen en la libreria
 */
.marketplace-content .ant-input-number{
    width: 100% !important;
    background-color: transparent !important;
    border: none !important;
}

/*
 * @component Marketplace -> Input
 * @description Establecer el color de los placeholders
 */
.marketplace-content .ant-input::placeholder{
    color: rgba(255, 255, 255, 0.5);
}

/*
 * @component Marketplace -> Button
 * @description Establcer y Corregir estilos de los botones "Anterior" y "Siguiente"
 */
.marketplace-step-button{
    background-color: #df3d42 !important;
    border: none !important;
    border-radius: 2rem !important;
    color: white !important;
    font-weight: 700 !important;
    padding: 0.5rem 3rem !important;
}

/*
 * @component Marketplace -> StepCuenta -> Form.Item 
 * @description Centrar el label del formulario
 */
.cuenta-form-item .ant-form-item-label{
    text-align: center !important;
}

/*
 * @component Marketplace -> StepCuenta -> Link
 * @description Darle color al link
 */
.cuenta-form-link{
    border: none !important;
    background-color: transparent !important;
    font-weight: 500 !important;
    padding: 0 0 0 0.35rem !important;
}

.cuenta-form-link span{
    color: #df3d42 !important;
}

/*
 * @component Marketplace -> StepCotizador -> Button
 * @description Establecer el fondo y quitar el borde al boton
 */
.cotizador-button-add{
    background-color: #1890FF !important;
    border: none !important;
}

/*
 * @component Marketplace -> StepCotizador -> Button 
 * @description Establcer peso y decoracion del texto, y el fondo sin borde del boton
 */
.cotizador-text-add{
    font-weight: bold;
    text-decoration: underline;
    background-color: transparent !important;
    border: none !important;
}



.auth-container{
    width: 100%;
    max-width: 300px;
    margin: 2em auto; 
    /* style={{ width: '100%', maxWidth: 300, margin: '0 auto' }} */
}


.button-login-cotizador{
    border-radius: 100px !important;
    margin-top: 1em !important;
    background-color: #df3d42 !important;
}

.button-login-cotizador span{ 
    text-transform: uppercase !important;
    font-size: 12px;
    position: relative;
    bottom: 3px;
}

.edit-input-button{
    border: none !important;
    background: transparent !important;
    display: inline-block !important;
}

.edit-input .ant-input-group-addon{
    background: transparent !important;
}

.edit-input .ant-input {
    border-radius: 5px !important;
}

.edit-input-hide{
    display: none;
}

.form-item-review .ant-form-item-label{
    margin-bottom: 0 !important;
    padding: 0 0 3px 0 !important;
}

.form-item-review .ant-form-item-label label{
    font-size: 12px;
    font-weight: 300;
}


.products-collapse {

    background-color: unset !important;
}

.products-collapse .ant-collapse-item{

    background-color: rgba(0, 0, 0, 0.15);
    margin-bottom: 1em;
    border-radius: 3px !important;
    border: unset!important;
}

.products-collapse .step-review-icon{
    font-size: 18px;
    
    transition: all 0.3s ease;
}


.products-collapse .step-review-icon.red,
.products-collapse .step-review-icon.red *{
    color: #D82729 !important;
}
.products-collapse .step-review-icon.blue,
.products-collapse .step-review-icon.blue *{
    color: #267DCD !important;
}

.products-collapse .ant-collapse-header .ant-list-item-action em{
    display: none !important;
}

.products-collapse .ant-collapse-header > div{
    display: inline-block !important;
}    


.rotate-icon {
    transform: rotate(-90deg);
} 