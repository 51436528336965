@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&family=Poppins:wght@100;200;300;400;500;600;700&family=Roboto:wght@100;300;400;500;700&display=swap');
/*CARD TASK CLIENT CSS*/

/*
 * @component CardTaskClient
 * @description estilos del card principal
 */

.card-task {
    margin: 0.5rem 0 0 0 !important;
    min-width: auto;
    max-width: 365px;
    border-radius: 12px !important;
}

/*
 * @component CardTaskClient
 * @description estilos de la descripcion del card
 */

.card-task-description {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #5E5E7A;
}

/*
 * @component CardTaskClient
 * @description estilos del tag del card
 */

.card-task-tag {
    border-radius: 4px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
    padding: 5px 8px !important;
}

.card-task-tag-spot-vmi {
    background: #EAE5FF !important;
    border: none !important;
    color: #7B61FF !important;
    width: 80px;
    font-size: 15px !important;
    font-weight: 600;
    text-align: center;
}

/*
 * @component CardTaskClient
 * @description Quita el borde del head del card
 */

.card-task .ant-card-head {
    border: none !important;
}

/*
 * @component CardTaskClient
 * @description estilos del titulo del head del card
 */

.card-task .ant-card-head .ant-card-head-title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #2E2E4D;
}

/******************* CARD MANTENIMIENTO TASK ********************************/
.card-mantenimiento-task{
    margin-bottom: 1rem !important;
}
.card-mantenimiento-task-title.ant-typography{
    font-family: Poppins;
    font-style: normal;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #2E2E4D;
}

.card-mantenimiento-task-subtitle{
    font-family: Poppins;
    font-style: normal;
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    color: #2E2E4D;
}

.card-mantenimiento-task-location{
    font-size: 13px;
    font-weight: 700; 
    color: #5E5E7A;  
}

.card-mantenimiento-task-address{
    font-size: 13px;
    font-weight: 500;
    color: #5E5E7A;
}

.card-mantenimiento-task-description{
    font-size: 13px;
    font-weight: 400;
    color: #000000;
    height: 80px;
    display: block;
    overflow: auto;
}

.card-mantenimiento-task-client{
    font-size: 10px;
    font-weight: 600;
    line-break: normal;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis!important;
}

.card-mantenimiento-task-update{
    font-size: 10px;
    font-weight: 300;
}

.card-mantenimiento-task-tag {
    border-radius: 4px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
    padding: 0 10px !important;
}

.card-mantenimiento-task-name{
    font-family: Poppins;
    font-size: 10px;
    font-weight: 600;
    line-height: 24px;
}
/******************* CARD TASK NAME CSS ********************************/

/*
 * @component CardTaskStep
 * @description estilos del card principal
 */

.card-task-name {
    margin: 0 0 0 0 !important;
    min-width: auto;
    max-width: 365px;
    border-radius: 8px !important;
}

/*
 * @component CardTaskStep
 * @description estilos del card simple
 */
.card-task-name.card-task-simple{
    max-width: none;
}

/*
 * @component CardTaskStep
 * @description hace un espacio interno al cover (imagen) para que se vea dentro del card
 */

.card-task-name .ant-card-cover {
    padding: 5px 8px;
}

/*
 * @component CardTaskStep
 * @description estilos de la "imagen" en este caso background color del card
 */

.card-task-color {
    height: 100px;
    width: 100%;
    border-radius: 8px;
}

/*
 * @component CardTaskStep
 * @description estilos de la "imagen" en este caso background color del card con texto
 */

.card-task-simple.card-task-color {
    height: auto;
    padding: 16px 24px;
    color: #FFF;
    font-weight: 600;
    font-size: 16px;
    width: 100%;
    border-radius: 8px;
}

/*
 * @component CardTaskStep
 * @description estilos de la fuente del meta del card
 */

.card-task-name-meta .ant-card-meta-detail {
    font-family: Poppins;
    font-style: normal;
}

/*
 * @component CardTaskStep
 * @description estilos del meta title del card
 */

.card-task-name-meta .ant-card-meta-detail .ant-card-meta-title {
    /* Heading */
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #2E2E4D !important;
}

/*
 * @component CardTaskStep
 * @description estilos del meta de la descripcion del card
 */

.card-task-name-meta .ant-card-meta-detail .ant-card-meta-description {
    font-family: Poppins;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #5E5E7A;
}

/*
 * @component CardStepTracker
 * @description estilos del card 
 */
.card-shadow {
    background: #FFFFFF;
    border-radius: 10px !important;
    box-shadow: 0px 30px 60px rgba(32, 56, 85, 0.15);

}


/*
 * @component CardTaskStep
 * @description estilos del meta del card del titulo del card
 */

.card-task-list-meta .ant-list-item-meta-title {
    font-family: Poppins;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #5E5E7A;
}

/*
 * @component ProjectManager -> div
 * @description Margen del contenedor de los task para poder ver el background y la separacion de Cols
 */

.col-list-task-margin {
    padding: 10px;
}

/*
 * @component CardSaldosGraph
 * @description Borde del card
 */

.card-saldos-dashboard {
    border-radius: 10px !important;
}

/*
 * @description tamaño del height para que se vea la grafica
 */

.card-saldos-dashboard .ant-card-cover {
    height: 150px;
}

/*
 * @description Elimina el padding para que el contenido se ajuste mejor
 */

.card-saldos-dashboard .ant-card-body {
    padding: 0;
}

/*
 * @description Estilos de la fuente del HEAD del card
 */

.card-saldos-dashboard .ant-card-head {
    border: none;
    /* font-family: Lato; */
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    color: #858997;
}

/*
 * @component CardOperations
 * @description Asigna el fondo transparente
 */

.card-operations-dashboard {
    background: transparent !important;
}

/*
 * @description Estilos del Head del Card
 */
.card-operations-dashboard .ant-card-head {
    border: none;
    padding: 0 1rem;
    min-height: 15px !important;
    /* font-family: Inter; */
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.009em;
    color: #262631;
}

/*
 * @description Estilos del Head del Card
 */
.card-operations-dashboard .ant-card-head .ant-card-head-wrapper .ant-card-head-title {
    padding: 0 !important
}

/*
 * @description Modificacion al padding del Body del Card
 */
.card-operations-dashboard .ant-card-body {
    padding: 0 1rem;
}

/*
 * @description Modificacion al padding del Meta del Card
 */
.card-operations-dashboard .ant-card-body .ant-card-meta {
    padding: 1rem 0;
}

/*
 * @description Estilos de fuente del Meta title del Card
 */
.card-operations-dashboard .ant-card-body .ant-card-meta .ant-card-meta-detail .ant-card-meta-title {
    /* font-family: Inter; */
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 35px;
    color: #2E2E3A;
}

/*
 * @description Estilos de fuente del Meta descripcion del Card
 */
.card-operations-dashboard .ant-card-body .ant-card-meta .ant-card-meta-detail .ant-card-meta-description {
    /* font-family: Inter; */
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #656575;
}

/*
 * @description Estilos del texto de porcentaje e icono  rojos
 */
.operations-down, .operations-down svg {
    /* font-family: Inter; */
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.009em;
    color: #FD4438;
}

/*
 * @description Estilos del texto de porcentaje e icono verdes
 */
.operations-up, .operations-up svg {
    /* font-family: Inter; */
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.009em;
    color: #24CA49;
}

/*
 *
 * @description Card que se encuentra dentro del card de Operations,Asignacion de estilos del card
 */
.card-operations-dashboard-inner {
    border-radius: 16px !important;
    height: 225px;
}

/*
 * @description Modifacion de espacios y tamaño del head del card
 */
.card-operations-dashboard-inner .ant-card-head {
    padding: 0 1rem;
    height: 5px;
}

/*
 * @description Modifacion de espacios del body para que no abarque tanto espacio
 */
.card-operations-dashboard-inner .ant-card-body {
    padding: 0 1rem;
}

/*
 * @description Estilos del texto del card
 */
.card-operations-dashboard-inner-title {
    /* font-family: Inter; */
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    color: #27272E;
    padding: 0.4rem 0;
    margin: 0.5rem 0;
}

/*
 * @description Estilos del texto del card para el progress bar
 */
.card-operations-dashboard-progress-text {
    /* font-family: Inter; */
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    color: #84859E;
    margin: 2px 0px;
}

/*
 *
 * @description Contenedor del icono del Tools del card
 */
.box-icon-rounded {
    background: rgba(0, 205, 152, 0.2);
    border-radius: 8px;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    justify-self: center;
    vertical-align: middle;
}



@media screen and (min-width:1800px) {
    .col-list-task-margin {
        max-width: 450px;
    }

    .card-task-name, .card-task {
        display: block;
        margin: 0 auto 10px auto !important;
    }
}

/*
 * @component CardProducto 
 * @description Estilos para el display de informacion del Card
 */

.card-producto {
    background-color: rgba(0, 0, 0, 0.15) !important;
    border: none !important;
}

.card-producto .ant-card-body {
    padding: 1rem !important;
}

.card-producto-thumb {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 5px;
}

.card-producto-button {
    background-color: transparent !important;
    border: none !important;
    padding: 0 !important;
    padding: 0 0.75rem !important;
    height: auto !important;
}

.card-producto-button:nth-child(1) * {
    color: #267DCD !important;
}

.card-producto-button:nth-child(2) * {
    color: #D82729 !important;
}

.card-producto-cantidad {
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}


/*
 * @component CardSaldo
 * @description estilos del meta del card de Saldo al momento de Finanzas
 */
.card-saldo {
    border-radius: 10px !important;
    min-height: 239px;
}

/*
 * @component CardSaldo
 * @description el titulo del Card
 */
.card-saldo .card-saldo-main-meta .ant-card-meta-title {
    color: #858997;
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 1.2rem;
}

/*
 * @component CardSaldo
 * @description estilos para el monto total de las cuentas
 */
.card-saldo .card-saldo-main-meta .ant-card-meta-description {
    font-weight: bold;
    font-size: 30px;
    color: #000;
    margin-bottom: 15px;
}

/*
 * @component CardSaldo
 * @description estilos del small
 */
.card-saldo .card-saldo-main-meta small {
    font-size: 14px;
}

/*
 * @component CardSaldo
 * @description estilos del meta del card de Saldo al momento de Finanzas
 */
.card-saldo .card-saldo-sub-meta .ant-card-meta-title {
    font-weight: 600;
    font-size: 12px;
    color: #858997;
}

/*
 * @component CardSaldo
 * @description estilos del meta del card de Saldo al momento de Finanzas
 */
.card-saldo .card-saldo-sub-meta .ant-card-meta-description {
    font-weight: 600;
    font-size: 12px;
    color: #0047FF !important;
    margin-bottom: 20px;
}

/*
 * @component CardCuenta
 * @description limita el tamaño de cada contenedor principal del carrousel
 */
.carousel-cards .react-multi-carousel-item {
    max-width: 387px;
}

/*
 * @component CardCuenta
 * @description Estilo para la tarjeta bancaria
 */
.card-cuenta {
    border-radius: 20px !important;
    margin-right: 1rem !important;
    min-height: 239px;
    width: 370px;
    box-shadow: 1px 3px 2px rgba(0, 0, 0, 0.5);
    margin-bottom: 5px !important;
}

.card-cuenta .btn-purple {
    background: rgba(169, 6, 245, 0.75);
    color: white;
    border: none;
    font-size: 12px;
}

/*
 * @component CardCuenta
 * @description Estilo para el nombre de la cuenta
 */
.card-cuenta .cuenta-nombre {
    font-size: 18px;
    color: #FFFFFF;
}

/*
 * @component CardCuenta
 * @description Estilo para el saldo principal de la cuenta
 */
.card-cuenta .card-saldo-sub-meta .ant-card-meta-title {
    font-weight: bold;
    font-size: 30px;
    color: #FFFFFF;
    margin-top: 0.9rem;
}

/*
 * @component CardCuenta
 * @description Estilo para el saldo secundario de la cuenta
 */
.card-cuenta .card-saldo-sub-meta .ant-card-meta-description {
    font-weight: bold;
    font-size: 18px;
    color: #FFFFFF;
    margin-bottom: 0.5rem;
}

/*
 * @component CardCuenta
 * @description Estilo para el saldo secundario de la cuenta
 */
.card-cuenta .card-saldo-sub-meta .ant-card-meta-title small {
    font-size: 14px;
}

/*
 * @component CardGains
 * @description Estilo para los cards de profit y loss
 */
.card-gains {
    /* font-family: Inter; */
    font-style: normal;
    max-height: 250px;
    border-radius: 16px !important;
}

/*
 * @component CardGains
 * @description Estilo para el titulo del card
 */
.card-gains .card-title {
    font-weight: 600;
    font-size: 16px;
    color: #27272E;
}

/*
 * @component CardGains
 * @description Estilo para los montos del card
 */
.card-gains .text-paid {
    font-weight: normal;
    font-size: 14px !important;
    color: #84859E;
}

/*
 * @component CardGains
 * @description Estilo para los montos del card
 */
.card-gains .dots {
    padding-right: 5px;
}

/*
 * @component CardGains
 * @description Estilo para los montos del card
 */
.card-gains .dots svg {
    transform: rotate(90deg);
}

/*
 * @component CardGains
 * @description Estilo para el icon del card
 */
.card-gains .green-box {
    background: rgba(29, 210, 0, 0.1);
    height: 48px;
    width: 50px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

/*
 * @component CardGains
 * @description Estilo para el icon del card
 */
.card-gains .red-box {
    background: rgba(229, 10, 10, 0.1);
    height: 48px;
    width: 50px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
}

/*
 * @component CardGains
 * @description Estilo para el icon del card
 */
.card-gains .red-box svg,
.card-gains .green-box svg {
    width: 25px;
}

.card-head-borderless .ant-card-head {
    border-bottom: 0;
}

.card-project .ant-card-head,
.card-task-progress .ant-card-head,
.card-address .ant-card-head,
.card-utilidad .ant-card-head {
    padding: 0rem 1rem;
    border-bottom: 0;
    height: 20px;
}

.card-project .ant-card-body,
.card-task-progress .ant-card-body,
.card-address .ant-card-body {
    padding: 0 1rem 1rem 1rem;
}

.card-utilidad .ant-card-body {
    padding: 0px 1rem;
}

.card-task-progress {
    max-height: 270px;
}

.card-address .answer {
    font-size: 13px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0.30000001192092896px;
    color: #1778B0;

}

.card-project {
    overflow: auto;
}

.card-project .ant-card-head-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.3px;
    color: #121212;
}

.card-project-info {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 21px;
    letter-spacing: 0.5px;
    color: #121212;
    opacity: 0.7;

}

.utilidad-text {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 21px;
    letter-spacing: 0.3px;
    color: #121212;
}

.card-account-purchases .ant-card-head {
    padding: 0 !important;
    max-height: 30px !important;
    border: none;
}

.card-account-purchases .ant-card-body {
    padding: 10px 0px 10px 10px !important;
}

/*
 * @component CardGains
 * @description Estilo para el Titulo del card
 */
.card-title-purple {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #5C54E0 !important;
}

/*
 * @component Text
 * @description Estilo para el icon del card
 */
.card-monto-gray {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.3px;
    color: #121212;
    opacity: 0.5;
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: 32px;
}

/*
 * @component Tag
 * @description Estilo para el icon del card
 */
.tag-spot-vmi {
    background: #96D3FF !important;
    border: none !important;
    color: #0070F3 !important;
    width: 80px;
    font-size: 15px !important;
    font-weight: 600;
    text-align: center;
}

/*
 * @component CardProductEstatus
 * @description Estilo para el icon del card
 */
.card-estatus-text {
    /* font-family: Lato; */
    font-style: normal;
    font-weight: 600 !important;
    font-size: 15px;
    line-height: 2.24rem;
}

/*
 * @component CardProductEstatus
 * @description Estilo para el texto del card
 */
.card-estatus-cantidad {
    /* font-family: Lato; */
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #000000;


}

/*
 * @component CardProductVentas
 * @description Estilo para la cantidad del card
 */
.card-cantidad-blue {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #2D348C;
}

/*
 * @component CardFactura
 * @description Estilo para los botones del card
 */
.card-factura-button {
    display: flex !important;
    width: 28px !important;
    height: 28px !important;
    align-items: center;
    justify-content: center;
}

.card-factura-button svg {
    display: block !important;
}

/*
 * @component CardIncomeExpense
 * @description Estilo para los botones del card
 */

.card-income-expense-text {
    color: #222222 !important;
}

.card-income-expense-button {
    display: flex !important;
    width: 28px !important;
    height: 28px !important;
    align-items: center;
    justify-content: center;
}

.card-income-expense-button svg {
    display: block !important;
}

/*Cards del CRM*/
.card-title-gray .ant-card-head-title {
    color: #858997;
    font-size: 1.25rem;
    display: block;
    word-wrap: break-word;
}


.card-centros {
    max-width: 350px;
    height: 400px;
    max-height: 400px;
}

.card-centros .ant-card-head,
.card-centros .ant-card-actions,
.card-centros li {
    border: none !important;
}

.card-centros .grid-element {
    display: grid;
    justify-items: center;
    align-items: center;
    align-content: space-between;
}

.card-centros .card-centro-title {
    font-size: 16px !important;
}

.card-centros .card-centro-text-tiny {
    font-size: 14px;
    white-space: pre-wrap;
}

.card-centros .card-grid-md {
    width: 50%;
    text-align: center;
    padding: 10px;
    display: flex-column;
    border: none !important;
    box-shadow: none !important;
}

.card-centros .card-grid-lg {
    width: 100%;
    text-align: center;
    padding: 10px;
    display: flex-column;
    border: none !important;
    box-shadow: none !important;
}

.card-centros .card-grid-bottom {
    display: flex;
    flex-direction: column;
}

.card-centros .card-grid-bottom .actions-container {
    position: absolute;
    bottom: 0;
}



.card-producto-matriz{
    background: rgba(0, 34, 169, 0.05) !important;
    height: 100%;
}

.card-producto-matriz .desc{
    color: #686868 !important;
    font-weight: 300 !important;
}

.card-producto-matriz .blue-text{
    color: #2132CF !important;
}

.card-producto-matriz .categoria-tab{
    background-color: #F69E33 !important;
    border-radius: 10px !important;
    border-width: 0px !important;
    color: #FFFFFF;
    height: 25px;
    line-height: 25px;
    float: right;

}

.card-producto-matriz .ant-typography{
    font-size: 12px;
}

.card-producto-matriz .ant-col{
    font-size: 12px;
}

.card-producto-matriz .categoria-tab .ant-typography{
    font-weight: 500;
    /*height: 25px !important;*/
    color: #FFFFFF;
}




@media screen and (max-width: 1022px) {

    .card-gains {

        max-height: 300px;
        max-width: auto;

    }

}

.card-costos-mantenimiento .ant-card-head {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    color: #222222;
}

.card-costos-mantenimiento-head {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 23px;
    letter-spacing: 0.3px;
    color: #000000;
}

.tag-blue {
    border-radius: 50px !important;
    background: rgba(8, 160, 247, 0.5) !important;
    border: none !important;
    color: #5E5E7A !important;
    font-size: 14px!important;
    font-weight: 500;
}

.amount-bold {
    font-weight: 700;
    font-size: 0.8rem;
    line-height: 23px;
    letter-spacing: 0.3px;
}

.amount-green {
    color: #2E8B00 !important;
}

.amount-red {
    color: #FF003D !important;
}

.bold500 {
    font-weight: 500;
}

.sticky{
    position: sticky!important;
    top: 0%;
}

.card-form .ant-form-item-control{
    height: 25px;
}

.card-form .ant-form-item-control-input-content{
    height: 25px;
}

.card-form .ant-select{
    height: 25px !important;
}

.card-form .ant-select-selection-item{
    line-height: unset !important;
}

.card-form .ant-select-selector{
    height: 25px !important;
}

.card-form .ant-select-selection-search-input{
    height: 25px !important;
}

.card-form .ant-form-item-control-input{
    height: 25px !important;
}

.card-form .ant-select-selection-item{
    height: 25px !important;
}

.card-form .ant-input{
    border-radius: 5px !important;
    border: none !important;
    background-color: rgba(0, 34, 169, 0.05);
    height: 25px;
}

.card-form .ant-row{
    min-height: 30px !important;
}

.card-form .ant-col{
    height: 30px !important;
}

.card-form .ant-form-item-control-input{
    height: 30px !important;
    min-height: unset !important;
}

.card-matriz-nacional .ant-card-body{
    overflow: auto;
}

.card-matriz-nacional .ant-card-body > .ant-form{
    min-width: 1000px;
}

.card-matriz-nacional-label{
    background-color: #0022A9;
    border-radius: 3px;
    padding: 7px 0px;
    display: flex !important;
    justify-content: center !important;
}

.card-matriz-nacional-gutter{
    column-gap: 7px;
    row-gap: 10px;
}

.card-matriz-nacional-label > .ant-typography{
    color: white;
    font-weight: 500;
}

.card-matriz-nacional-input, .card-matriz-nacional-calculado{
    background-color: rgba(0, 34, 169, 0.05);
    border-radius: 3px;
}

.card-matriz-nacional-calculado .ant-form-item-control-input-content{
    display: flex;
    justify-content: flex-end;
    padding: 0 16px;
}

.card-matriz-nacional-input .ant-form-item, .card-matriz-nacional-calculado .ant-form-item{
    margin-bottom: 0px;
}

.card-matriz-nacional-input .ant-input-number{
    background-color: transparent !important;
}

.card-matriz-nacional-input .ant-input-number-input{
    text-align: center;
}

.card-logistica{
    border-radius: 8px;
}

.card-logistica .ant-card-body{
    padding: 16px !important;
}

.card-logistica .card-logistica-section{
    color: #D6009A;
}

.card-logistica .tag-card{
    border-radius: 20px;
    padding: 7px 12px;
    font-weight: 600;
    color: #D6009A;
    background-color: #d6009A33;
}

.card-especificaciones.card-form .ant-col{
    height: auto !important;
}

.card-especificaciones.card-form .ant-space-item .ant-form-item{
    margin-bottom: 0;
}