.button-floating {
    background: linear-gradient(126.63deg, #2D348C 11.98%, #2D348C 83.35%) !important;
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.35), 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
}


.m-1 {
    margin: 10px 0px;
}

.mlr-1 {
    margin: 0px 10px;

}

.pt-5px {
    padding-top: 5px;
}


.mt-50px {
    margin-top: 50px;
}

.text-back {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;

    color: #666;
    mix-blend-mode: normal;


}

.btn-eye, .btn-link, .btn-editar {
    border-color: transparent !important;
}


.btn-eye {
    background: #ADFF45 !important;
}

.btn-link {

    background: #FFE608 !important;
}

.spn-estatus, .spn-tipo {

    background: #C4C4C4;
    border-radius: 20px;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    mix-blend-mode: normal;
    color: #AAA;
    padding: 5px 20px;

}

.text-monto {
    font-style: normal;
    font-weight: bolder;
    font-size: 17px;
    line-height: 20px;
    color: #555 !important;
}


.item-info {
    padding: 5px 0px;
}

.card-rs-blue {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.5px;
    color: #6c71af;
}

.card-rs-text, .card-rs-cantidad {
    margin: 5px 0px;
}

.card-rs-text {

    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.3px;
    color: #888888;
}


.card-rs-cantidad {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.5px;
    color: #4d4d4d;

}

.component-list .component-list-item {
    padding: 6px 0px !important;
}

.text-green {

    font-size: 16px;
    line-height: 20px;
    color: #70cb68 !important;
}





.row-sinSoporte svg,
.row-incompleto svg,
.row-completo svg {
    width: 18px;
}


.row-sinSoporte .spn-titulo,
.row-incompleto .spn-titulo,
.row-completo .spn-titulo {
    display: inline-block;
    top: -10px;
    position: inherit;
    margin-left: 10px;
}


.row-sinSoporte .spn-titulo {
    color: #fe4141 !important;
}

.row-incompleto .spn-titulo {
    color: #faad14 !important;

}

.row-completo .spn-titulo {
    color: #52c41a !important;

}



.row-sinSoporte strong,
.row-incompleto strong,
.row-completo strong {
    display: inline-block;
    top: -12px;
    position: inherit;


    font-style: normal;
    font-weight: 800;
    font-size: 15px;
    line-height: 24px;
    color: #000000;
    top: -10px;
}


@media (min-width: 1600px) {

    .col-fct, .col-trs {
        padding: 2px;
    }

    .col-fct {
        padding-right: 10px;
    }

    .col-trs {
        padding-left: 10px;

    }

    .col-fct .ant-btn,
    .col-trs .ant-btn {
        width: 25px !important;
        height: 25px !important;
        padding: 0px !important;
    }



    .col-fct .card-list,
    .col-trs .card-list {
        min-height: 70px;
    }

}