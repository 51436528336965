.layout-content {
    background: #F4F8FB !important;

}

/*
 * @component Layout -> Content
 * @description Asigna el tamaño del content, se utiliza el calculo de toda la pantalla menos el tamaño del header, menos el tamaño del pageHeader
 */

.admin-content {
    min-height: 800px !important;
}

/*
 * @component ProjectManager -> Content
 * @description background color del content de la vista de project manager
 */

.content-bg {
    background: #F4F8FB !important;
}

/*
 * @component ProjectManager -> Col
 * @description background color del col de los card de task
 */

.col-list-task-bg {
    background: #F2EFF5;
}

/*
 * @component CardTaskClient -> List
 * @description Elimina el border-bottom del los items de la lista
 */

.list-border-none ul .ant-list-item {
    border: none !important;
}


.list-crm-scroll {
    max-height: 330px;
    overflow-y: auto;
}

/*
 * @component Checkbox 
 * @description Hace el checkbox redondo
 */

.custom-checkbox-circle .ant-checkbox-checked::after {
    border-radius: 50px !important;
    border: 1.5px solid #2C3E50;
    height: 20px;
    width: 20px;
}

/*
 * @component Checkbox 
 * @description Acomoda el input dentro del checkbox
 */

.custom-checkbox-circle .ant-checkbox {
    left: -3px;
    top: -2px;
}

/*
 * @component Checkbox 
 * @description Hace el checkbox inner redondo
 */

.custom-checkbox-circle .ant-checkbox input, .custom-checkbox-circle .ant-checkbox .ant-checkbox-inner {
    border-radius: 50px;
    border: 1.5px solid #2C3E50;
    height: 20px;
    width: 20px;
}

/*
 * @component Divider 
 * @description cambia el color del divider de projectManager
 */

.border-task {
    border: 1px solid #BDBDFE;
}

/*
 * @component RangePicker 
 * @description Hace el ranger transparent con bordes blancos y cuadrado
 */

.filter-range {
    background: transparent !important;
    border: 1px solid #fff !important;
    color: #fff !important;
    border-radius: 0px !important;
    height: 30px;
}

/*
 * @component RangePicker 
 * @description Hace el ranger icon y el placeholder blancos
 */

.filter-range .ant-picker-input input::placeholder, .filter-range .ant-picker-suffix .anticon>svg, .filter-range .ant-picker-input .ant-picker-range-separator .ant-picker-separator .anticon>svg {
    color: #fff !important;
}

/*
 * @component RangePicker 
 * @description El borderBottom cuando seleccionas un campo lo pone blanco
 */

.filter-range .ant-picker-active-bar {
    background: #fff !important;
}

/*
 * @component Button 
 * @description Hace el borde transparente
 */

.button-borderless {
    border: 1px solid transparent !important;
}

/*
 * @component Button 
 * @description Boton de descarga de los filtros, lo hace mas grande y el icono blanco
 */
.button-download, .button-download svg {
    font-size: 25px !important;
    color: #fff;
}

/*
 * @component Button 
 * @description Estilo de BOTON para ver, color verde. Se usó en FACTURAS
 */
.button-eye {
    background: #87d068 !important;
    border: none !important;
}

/*
 * @component Button 
 * @description Estilo de BOTON para ver PDF, color azul. Se usó en FACTURAS
 */
.button-pdf {
    background: #2db7f5 !important;
    border: none !important;
}

/*
 * @component Header 
 * @description Cambia el color del Header y la fuente
 */

.ant-layout-header {
    color: #FFFFFF;
    line-height: 64px;
    background: #0022A9 !important;
}

/*
 * @component PageHeader 
 * @description Cambia el color del PageHeader y la fuente, 
 * se esta sobreescribiendo la clase nativa ya que
 * no hay variables para modificarlo
 */

.ant-page-header {
    box-sizing: border-box;
    margin: 0;
    color: #fff;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    position: relative;
    padding: 16px 24px;
    background-color: #0022A9 !important;
}

/*
 * @component PageHeader 
 * @description Cambia  la fuente del titulo del PageHeader, 
 * se esta sobreescribiendo la clase nativa ya que
 * no hay variables para modificarlo
 */

.ant-page-header-heading-title {
    margin-right: 12px;
    margin-bottom: 0;
    /* font-family: Lato; */
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 3px;
    color: #FFFFFF !important;
    font-weight: 600;
    font-size: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

/*
 * @component Button 
 * @description Ajusta el boton del Upload a la esquina inferior derecha 
 */

.btn-upload-delete {
    display: block;
    position: absolute !important;
    bottom: 20px;
    right: 0%;
}

/*
 * @component List->Header 
 * @description Cambia el texto a mayusculas del header de la lista
 */
.header-list {
    text-transform: uppercase;
    padding-left: 1rem;
    padding-right: 1rem;
}

/*
 * @component None
 * @description Asigna un borde de 16px 
 */
.border-16 {
    border-radius: 16px;
}

/*
 * @component None
 * @description Asigna un borde de 16px 
 */
.border-10 {
    border-radius: 10px !important;
}

/*
 * @component Tag
 * @description Le quita el border radius al tag
 */
.modulo-tag {
    border-radius: 0px !important;
}



/*
 * @component TableContainerXML 
 * @description Esto es para el diseño de las tablas que permiten visualizar el XML
 */
.table-container {
    width: 100%;
    overflow: auto;
    max-height: 500px;
}

/*
 * @component TableContainerXML 
 * @description Para los divs-rows de la tabla
 */
.div-row {
    display: flex;
}


/*
 * @component TableContainerXML 
 * @description Para lso nombres de los elementos de la table
 */
.div-row .div-key {
    width: 25%;
    border-bottom: solid 1px black;
    background-color: #90A7F8;
    border-right: solid 1px black;
    border-left: solid 1px black;
    padding-left: 0.5rem;
    min-width: 120px;
}

/*
 * @component TableContainerXML 
 * @description Para los titutlos de los elementos de la table XML
 */
.div-title {
    width: 100%;
    background-color: #0047FF;
    color: white;
    border-bottom: solid 1px black;
    padding-left: 1rem;
}

/*
 * @component TableContainerXML 
 * @description No se, preguntarle a manuel el lo hizo
 */
.div-string {
    border-bottom: solid 1px black;
    border-right: solid 1px black;
    width: 75%;
    padding-left: 1rem;
    min-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-height: 200px;
}

/*
 * @component TableContainerXML 
 * @description Colores de los bordes de la tabla XML
 */
.factura-xml-table {
    border-left: solid 1px black;
    border-right: solid 1px black;
    border-bottom: solid 1px black;
}

/*
 * @component TableContainerXML 
 * @description para mantener la estructura de las posiciones de los elementos
 */
.table-container thead th {
    position: sticky;
    top: 0;
    z-index: 1;
}

/*
 * @component TableContainerXML 
 * @description Mismo punto,pero para todo el TH global, no solamnete para headers
 */
.factura-xml-table th {
    text-transform: capitalize;
    padding-right: 2rem;
    border-right: solid 1px black;
    border-bottom: solid 1px black;
    background-color: #cacaca;
}

/*
 * @component TableContainerXML 
 * @description Para los bordes de los TDS
 */
.factura-xml-table td {
    text-transform: capitalize;
    border-right: solid 1px black;
    border-bottom: solid 1px black;
}



.color-waiting {
    background-color: #FAAD14 !important;
}

.color-accepted {
    background-color: #52C41A !important;
}

.color-rejected {
    background-color: #FF4D4F !important;
}


/* Buttons colors */
.btn-primary {
    border: none !important;
    background: #456EFF !important;
}
.btn-borderless {
    border: none !important;
    box-shadow: none !important;
}

.panel-list{
    min-height: 68px;
    display: grid;
}

.panel-list .ant-collapse-extra {
    width: 100%;
}

.custom-collapse{
    padding: 6px 0 !important;
}

.custom-collapse,
.custom-collapse .ant-collapse-item .ant-collapse-content .ant-collapse-content-box {
    border: none !important;
    background: transparent !important;
}

.custom-collapse .ant-collapse-item,
.custom-collapse .ant-collapse-item {
    
    overflow: hidden;
    background: #fff;
    border: none !important;
    border-radius: 2px;
}


/*
 * @component Button 
 * @description Para los botones deshabilitados
 */
.btn-primary[disabled],
.ant-btn-primary-green[disabled],
.ant-btn-primary-lime[disabled],
.ant-btn-primary-red[disabled],
.ant-btn-primary-purple[disabled]   {
    background: #f5f5f5 !important;
    border: 1px solid #ccc !important;
}

/*
 * @component Button 
 * @description Para los iconos de los botones deshabilitados
 */
.btn-primary[disabled] svg,
.ant-btn-primary-green[disabled] svg,
.ant-btn-primary-lime[disabled] svg,
.ant-btn-primary-red[disabled] svg,
.ant-btn-primary-purple[disabled] svg {
    filter: invert(50%);
}


.btn-sider{
    position: fixed!important;
    top:164px;
    left: 240px;
    background: #fff;
    border-radius: 0px 10px 10px 0px!important;
    z-index: 2;
}

.btn-sider-collapse{
    position: fixed!important;
    top:164px;
    left: 0px;
    background: #fff;
    border-radius: 0px 10px 10px 0px!important;
    z-index: 2;
}
