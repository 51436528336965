.collapse-proveedores .ant-collapse-item{
    margin-bottom: 0.5rem;
}

.collapse-proveedores .ant-collapse-header {
   display: flex;
    border: 0px;
    border-radius: 8px !important;
    
}
.collapse-proveedores .ant-collapse-item .ant-collapse-content{
    font-family: Poppins;
    font-style: normal;
    font-size: 15px;
    vertical-align: middle;
    line-height: 50px;
    font-weight: 600;
}

.header-collapse span {
    font-family: Poppins;
    font-style: normal;
    font-size: 13px;
    line-height: 19px;
}

.collapse-text-big {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px!important;
    line-height: 24px;
}
.collapse-text-medium{
    font-size: 15px!important;
}

.collapse-icon-big svg{
    font-size: 25px;
}

.collapse-proveedores .ant-collapse-item{
    border:  none !important;
}