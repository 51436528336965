/**
 * Aplica para todas las vistas de Project manager
 */
.project-manager {
    /* Position Relative, ya que el position absolute del drawer solo le hace caso al primer position relative padre que se encuentre */
    position: relative;
    /* Previene que se genere un SCROLL en el eje X */
    overflow: hidden;
    /* TIene que tener el tamaño mínimo de la pantalla */


    padding: 1em 2em;
    margin: 0 !important;

}

/**
 * Aplica para el estilo el Project Tracker
 */
.project-tracker {
    /* Para las tranciciones */
    transition: all 0.2s ease;   
}

/**
 * Cuando se abre el project tracker
 */
.project-tracker-content.project-tracker-content-open{
    min-height: 1500px !important;
}


/**
 * Cuando está abierto el project tracker
 */
.project-tracker-open {
    /* Cuando está abierto, se posiciona en el lado izquierdo pero en 0 para que se muestre.. */
    position: absolute !important;
    top: 1em !important;
    left: 1em !important;
    width: 100%;
}

/**
 * Cuando está cerrado el project tracker
 */
.project-tracker-close {

    /* Cuando está cerrado, se posiciona en el lado izquierdo pero oculto. */
    position: absolute !important;
    top: 1em !important;
    left: -25%!important;
    width: 0px !important;
    overflow: hidden;
}


/**
 * Botón que abre y cierra el proyect tracker
 */
.project-tracker-button-sticky {
    position: absolute !important;
    top: 2em !important;
    border-bottom-left-radius: 0!important;
    border-top-left-radius: 0px !important;
    border-color: transparent !important;
    box-shadow: .125rem .125rem .25rem rgba(0,0,0,.075) !important;
}

/*
 * @component Tag
 * @description estilos del meta del card del titulo del card
 */
 .tag-card-blue {
    display: inline-block;
    background: #CEECFD;
    border-radius: 50px;
    min-width: 100px;
    color: #1778B0 !important;
    max-height: 30px;
    line-height: 15px;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;

    padding: 6px 15px;
}


.tag-card-pink {
    display: block;
    background: rgba(240, 221, 249,1);
    border-radius: 50px;
    min-width: 100px;
    width: auto;
    max-width: 200px;
    color: #B354E0 !important;
    padding: 4px;
    height: 27px;
    max-height: 30px;
    line-height: 15px;
    font-weight: 400;
    text-align: center;
}