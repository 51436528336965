.collapse-matriz {
    background: none !important;

    .panel-matriz {

        box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important;
        margin-bottom: 1em;
        background-color: white !important;

        border-width: 0 !important;
        border-radius: 6px !important;

        .ant-collapse-header {
            background-color: white !important;
            border-radius: 6px !important;
        }

        .ant-collapse-content {
            border-top: 1px solid #E0E5EB !important;
        }



        .ant-collapse-content>.ant-collapse-content-box {
            padding: 0 !important;
        }

        .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
            width: 0 !important;
        }

        .collapsable-title {
            font-size: 20px;
            font-weight: 500;
            line-height: 23px;
            letter-spacing: 0.30000001192092896px;
            color: #0022A9;
        }

        .collapsable-abbreviation {
            font-size: 20px;
            font-weight: normal;
            line-height: 23px;
            color: #0022A9;
        }

        .ant-collapse-header-text {
            flex: 1 !important;
            text-align: left;
        }

        
        .collapsable-icon-status {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 24px;
            height: 24px;
            border-radius: 50%;

            & > .anticon {
                color: #FFF !important;
            }

            &.red{
               background-color: #f5222d;
            }

            &.green{
                background-color: #52c41a;
            }
        }
    }


}

.fob-table {
    .ant-collapse-expand-icon {
        position: relative;
        top: 7px;
        color: #4600a8;
    }

    .ant-table-container {
        padding: 0 2rem !important;
    }

    .table-tag {
        border-width: 0px;
        background: rgba(0, 34, 169, 0.05);
    }

    .ant-table-thead {
        tr th {
            text-align: center !important;
            border: unset;
        }

        .ant-table-cell {
            background: #FFFFFF;
            border: none !important;
            font-weight: bold;
        }

        .join-title {
            color: #848484;

        }
    }

    .ant-table-cell {
        padding: 12px 8px !important
    }

    .ant-table table {
        margin-bottom: 2rem;
    }

    .ant-table-tbody>tr>td {
        border-bottom: unset !important;
        border-top: none !important;
        border-right: none !important;
        border-left: none !important;
    }

    .ant-table-body {
        overflow-y: hidden !important;
    }

    // .ant-table-tbody>tr.ant-table-row:hover>td {
    //     background: none !important;
    // }

}


.datos-base{
    
    
    .anticon, .anticon svg {
        color: #0022A9 !important; 
    }

    .ant-collapse-content-box{
        background-color: white !important;
    }

    .ant-collapse-borderless > .ant-collapse-item {
        // border-bottom: none;
        border-bottom: 1px solid #d4d4d4;
    }

    .ant-collapse-borderless > .ant-collapse-item.ant-collapse-item-active {
        border-bottom: none;
    }   

    .form-list .ant-list-items{
        border-bottom: 1px solid #d4d4d4;

    }

    &-concepto{
        .ant-form-item-control-input-content{
            text-align: left;
        }
    }

}



.matriz-costos {
    .table-matriz-costos {
        /*
            Margin para que la tabla no se encuentre al raz
        */
        margin: 0 0 0 1em;

        /*
            Quitamos todos los bordes
        */
        .ant-table-tbody > tr > td,
        .ant-table-thead > tr > th
        {
            border: none !important;
        }

        .ant-table-thead {

            .ant-table-cell {
                /*
                    Quitamos margenes, paddings ya que el column.title es quien tendrá eso, no el TR
                */
                background-color: white;
                margin: 0 !important;
                padding: 0 !important;

                /*
                    CSS general para todos los titulos
                */
                .column-title {
                    white-space: nowrap;
                    display: block;
                    padding: 1em;
                    text-align: center;
                }

                /* La primera linea debe de tener el borde rendoeado y el margen */
                &.start-line .column-title {
                    margin-left: 8px;
                    border-top-left-radius: 8px !important;
                    border-bottom-left-radius: 8px !important;
                }


                /* La ultima linea tambien, pero del otro lado */
                &.end-line .column-title {
                    margin-right: 8px;
                    border-top-right-radius: 8px !important;
                    border-bottom-right-radius: 8px !important;
                }

                /* Asignamos los colores a los headers */
                &.general .column-title {
                    background-color: #B3FACB !important;
                }

                &.incoterm .column-title {
                    background-color: #F1C0C3 !important;
                }

                &.prorrateo .column-title {
                    background-color: #FF8089 !important;
                }

                &.pedimentos_impuestos .column-title {
                    background-color: #55778D !important;
                    color: white;
                }

                &.logistica .column-title {
                    background-color: #F8FAB3 !important;
                }

                &.corporativo .column-title {
                    background-color: #b3facb !important;
                }

            }
        }

        .ant-table-cell {
            padding: 4px 8px !important
        }

        .first-item {
            padding-top: 25px !important;
        }
    }
}




.producto-tag {
    height: 50px !important;
}

.red {
    background-color: #D12C38 !important;
    border-color: #D12C38 !important;
    color: #FFFFFF !important;
}

.blue {
    background-color: #0C3D5C !important;
    border-color: #0C3D5C !important;
    color: #FFFFFF !important;
}

.blue-tag {
    background-color: #0022A9 !important;
    color: #FFFFFF !important;
}

.white-tag {
    background-color: #FFFFFF !important;
    border-color: #0022A9 !important;
    border-width: 1px !important;
    height: 30px !important;
}

.grey-tag {
    background-color: rgba(151, 151, 151, 0.2) !important;
}

.yellow-tag {
    background-color: #FFF2CC !important;
}

.resumen-table {
    .ant-table-body {
        overflow-y: auto !important;
    }
}

.fob {
    &-center-text {
        display: inline-block;
        width: 100%;
        text-align: center;
    }
}

.btn-confirmar,
.btn-editar {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.input-as-text {

    &.ant-input-number-disabled {
        background-color: none !important;
    }
    
    .ant-input-number-input-wrap{
        input{
            color: black !important
        }
    }
}

.flexed-0{
    left: 400px !important;
}
.flexed-1{
    left: 600px !important;
}
.flexed-2{
    left: 800px !important;
}
.flexed-3{
    left: 1000px !important;
}
.flexed-4{
    left: 1200px !important;
}
.flexed-5{
    left: 1400px !important;
}
.flexed-6{
    left: 1600px !important;
}
.flexed-7{
    left: 1800px !important;
}
.flexed-8{
    left: 2000px !important;
}

.input-red{

    border: none !important;

    input{
        background: #D12C38 !important;
        color: white;
        height: 32px;
    }
}

.input-blue{

    border: none !important;

    input{
        background: #0C3D5C !important;
        color: white;
        height: 32px;
    }
}

.select-matriz{
    height: 32px;

    .ant-select-selection-search{
        height: 32px !important;
    }
    .ant-select-selector{
        height: 32px !important;
    }

    .ant-select-selection-item{
        line-height: 32px !important;
    }
}

.input-select-red{
    .ant-select-selector{
        background: #D12C38 !important;
        color: white;
    } 
}


.input-select-blue{
    .ant-select-selector{
        background: #0C3D5C !important;
        color: white;
    } 
}