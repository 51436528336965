@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700&family=Lato:wght@100;300;400;700&family=Poppins:wght@100;200;300;400;500;600;700&family=Roboto:wght@100;300;400;500;700&display=swap');
/*
 * @component Title
 * @description Ajusta los estilos del titulo de cada seccion del dashboard
 */

.subtitle-dashboard {
    /* font-family: Inter, sans-serif; */
    font-style: normal;
    font-weight: 700;
    color: #2E2E3A!important;
}

/*
 * @component CardOperationList -> List
 * @description Estilos de fuente del header de la lista
 */

.top-list-header {
    /* font-family: Inter; */
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.009em;
    color: #262631;
}

/*
 * @component CardOperationList -> List
 * @description Quita el borde del head de la lista
 */

.list-top-dashboard .ant-list-header {
    padding: 10px 0px;
    border: none!important;
}

/*
 * @component CardOperationList -> List
 * @description Ajusta el padding de la lista a la del card
 */

.list-top-dashboard .ant-list-items .ant-list-item {
    padding: 6px 0px!important;
}

/*
 * @component CardOperationList -> List
 * @description Estilos del campo monto de la lista
 */

.list-top-dashboard-monto {
    /* font-family: Inter; */
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 22px;
    color: #2E2E3A;
}

/*
 * @component CardOperationList -> List
 * @description Estilos del campo rubro de la lista
 */

.list-top-dashboard .ant-list-items .ant-list-item .ant-list-meta-content {
    flex: 1 0;
    /* font-family: Inter; */
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.009em;
    color: #83838A;
}

.form-item-tipo-cambio label {
    background: transparent;
    /* font-family: Roboto; */
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #FFFFFF!important;
}

/*
 * @component label 
 * @description cambia el estilo label del dashboard tipo de cambio 
 */

.label-tipo-cambio {
    color: #FFFFFF;
    /* font-family: Roboto; */
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
}

/*
 * @component Input 
 * @description cambia el estilo input del tipo de cambio
 */

.input-tipo-cambio {
    background: transparent!important;
    color: #fff!important;
    border: 1px solid #E1E4ED!important;
    max-width: 200px;
    border-radius: 5px 0px 0px 5px!important;
    /* font-family: Roboto; */
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
}

/*
 * @component Input 
 * @description cambia el estilo input del tipo de cambio, al añadir el prefix, los estilos
 * de arriba ya no se aplican al input, por lo que añadieron nuevamente
 */
.input-tipo-cambio input{
    background: transparent!important;
    color: #fff!important;
    /* font-family: Roboto; */
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
}

/*
 * @component Button 
 * @description cambia el estilo button del tipo de cambio 
 */

.btn-tipo-cambio {
    background: #E1E4ED!important;
    border: 1px solid #E1E4ED!important;
    border-radius: 0px 5px 5px 0px!important;
}

/*
 * @component InputSearch 
 * @description cambia el estilo boton historial del tipo de cambio
 */

.btn-tipo-cambio-ouline {
    background: transparent!important;
    border: 1px solid #E1E4ED!important;
}
/*
 * @component InputSearch 
 * @description ajusta el tamaño maximo del contenedor del input tipo de cambio
 */
.historial-tipo-cambio{
    height: 475px;
   overflow-y: auto;
}


.modal-historial .ant-modal-content .ant-modal-header{
    border-bottom: none!important;
}