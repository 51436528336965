.button-floating {
    border: none!important;
    width: 80px !important;
    height: 80px!important;
    background: linear-gradient( 126.63deg, #8D8D8D 11.98%, #000000 83.35%) !important;
    box-shadow: 5px 5px 8px rgb(0 0 0 / 35%), 0px 4px 4px rgb(0 0 0 / 25%)!important;
    border-radius: 50px !important;
    bottom: 30px;
    right: 30px;
    z-index: 10;
    float: right!important;
    position: fixed !important;
    
}