/*
* @component RadioButton
* @description Ajusta los estilos del radio Button del formulario de ordenes de mantenimiento 
*/
.radio-yellow>.ant-radio-button-checked {
    background: #E2E200 !important;
    border: 1px solid #E2E200 !important;
}

.ant-radio-button-wrapper-checked span {
    color: #fff !important;

}

.radio-yellow {
    background: #fff !important;
    border: 1px solid #E2E200 !important;
    color: #000;
}

.tag-preventiva svg,
.tag-correctiva svg{
    color: #FFF;
}

.tag-preventiva{
    background: #E2E200 !important;
    color: white !important;
    border: 1px solid #E2E200 !important;
}


.radio-yellow svg,
.radio-red svg {
    color: #000;
}

.radio-yellow::before {
    background: transparent !important;
}

.radio-red {
    background: #fff;
    border: 1px solid #FF547D !important;
    color: #000;
}

.tag-correctiva{
    background: #FF547D !important;
    color: white !important;
    border: 1px solid #FF547D !important;
}

.radio-red>.ant-radio-button-checked {
    background: #FF547D;
    border: 1px solid #FF547D;
}

.ant-radio-button-wrapper-checked span,
.ant-radio-button-wrapper-checked svg {
    color: #fff !important;

}


/*
 * @component Form
 * @description Ajusta los estilos del formulario de ordenes de mantenimiento
 */
.form-blank {

    .ant-input,
    .ant-input::placeholder,
    .ant-select:not(.ant-select-customize-input) .ant-select-selector,
    .ant-select-selector .ant-select-selection-placeholder,
    .ant-picker {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 1px;
        color: rgba(0, 0, 0, 0.4) !important;
        background: #fff;
        opacity: 1;



    }

    .ant-form-item-label {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 2px;
        color: #000000;
    }

    .label-upper>.ant-form-item-label {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14 px;
        line-height: 24px;
        letter-spacing: 2px;
        color: #000000;
        text-transform: uppercase;
    }
}


/*
 * @component Card
 * @description Ajusta los estilos de titulo de los card type="inner"
 */
.card-inner {
    .ant-card-head .ant-card-head-wrapper .ant-card-head-title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 18px;
        color: #5E5E7A;
    }

    .card-text {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 2px;
        color: rgba(0, 0, 0, 0.4);


    }

    .card-subtitle {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 2px;
        color: #000000;
    }

    .card-subtitle-upper {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14 px;
        line-height: 24px;
        letter-spacing: 2px;
        color: #000000;
        text-transform: uppercase;
    }


}

/*
 * @component Collapse
 * @description Ajusta los estilos del collapse
 */
.collapse-blue {
    border: none !important;

    .ant-collapse-item {
        border: none;
        margin-bottom: 1rem;

    }

    .ant-collapse-header {
        border-radius: 10px !important;
        background: #fff;
    }

    .ant-collapse-item {
        border-bottom: 1px solid #fff !important;
        border-radius: 10px;
        box-shadow: 0px 30px 60px rgba(32, 56, 85, 0.15);


    }

    .ant-collapse-content-active {
        border-top: 1px solid #ECECEC !important;
    }

    .ant-collapse-header-text {
        width: 100%;
    }

    .ant-collapse-content-box {
        background-color: transparent !important;
    }

    .collapsable-title {
        font-size: 20px;
        font-weight: 500;
        line-height: 23px;
        letter-spacing: 0.30000001192092896px;
        color: #0022A9;
    }
}

/*
 * @component Table
 * @description Ajusta los estilos  de la tabla editable
 */
.table-editable {
    .ant-table-container table>thead>tr th {
        background: #fff;
        border: none !important;
        border-left-color: #fff;
    }

    .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
        border: none !important;
       // height: 0;
    }
    .ant-table-tbody > tr > td{
        border: none !important;

    }

    .ant-table tr{
        border: none!important;
    }
    .ant-table-footer button {
        float: right;
    }

    .editable-row{
        border-bottom: 1px #fff!important;
        
    }
    .editable-row td{
       // height: 40px!important;
    }

    .ant-form-item-control-input{
        min-height: unset !important;
    }
    .editable-cell-value-wrap {
        background: rgba(0, 34, 169, 0.05);
        border-radius: 5px;
        padding: 0 0 0 8px;
        height: 30px;
        max-height: 30px;
        line-height: 30px;
        //text-align: center;
    }

    .table-input-editable {
       // padding: 0px 2px;
        height: 30px;
        max-height:30px!important;
        line-height: 30px;
        margin: 0 !important;
        border: none;
    }
    .table-input-editable:focus {
        border: none!important;
        height: 30px;
        max-height:30px!important;
        margin: 0 !important;
    }
}