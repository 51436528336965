/*
 * @component Listas para el despliege de registros generales
 * @description Quita el border inferior de los elementos de la lista
 */

 .component-list {
    overflow-x: auto;
}

/*
 * @component Listas para el despliege de registros generales
 * @description Quita el border inferior de los elementos de la lista
 */

 .component-list .ant-list-header, .component-list .component-list-item {
    border: none;
    padding: 0 0 12px 0;
}

/*
 * @component Listas para el despliege de registros generales
 * @description pone la paginacion integradada a la lista del lado izquierdo
 */

.component-list .ant-list-pagination {
    text-align: left;
    margin-bottom: 5rem;
}


/*
 * @component Listas para el despliege de registros generales
 * @description Los cards dento de la lista tomen todo el ancho 
 */

.component-list .card-list {
    width: 100%;
}

/*
 * @component Listas para el despliege de registros generales
 * @description Da mas espacion interior a los cards de las lista
 */

.component-list .card-list .ant-card-body {
    padding: 13px;
    position: relative;
}

/*
 * @component Listas para el despliege de registros generales
 * @description pequeño linea de color que se encuentra en el lado izquierdo en cada elemento de la lista
 */
.component-list .card-list .badge-card {
    width: 6px;
    height: 78% !important;
    position: absolute;
    left: -2px;
    border-radius: 2px;
}


/*
 * @component Listas para el despliege de registros generales
 * @description color de la linea de la lista de transacciones, depentiendo del tipo de transaccion
 */
.component-list .card-list .badge-card.tipo-4 {
    background: gray;
}

/*
 * @component Listas para el despliege de registros generales
 * @description color de la linea de la lista de transacciones, depentiendo del tipo de transaccion
 */
.component-list .ant-list-header,
.component-list .ant-list-items {
    min-width: 1200px;
}

.component-list.medium-list .ant-list-items {
    min-width: 1000px;
}

/*
 * @component Listas para el despliege de registros generales
 * @description color de la linea de la lista de transacciones, depentiendo del tipo de transaccion
 */
.component-list.no-scroll .ant-list-header,
.component-list.no-scroll .ant-list-items {
    min-width: 100px;
}

/*
 * @component Listas para el despliege de registros generales
 * @description Elimina los bordes y lineas separadoras
 */
.list-no-border .ant-list-header{
    border: none !important;
}


/*
 * @component Widget de Syncfy, visualización
 * @description Permite visualizar el widget de syncfy
 */
.pb-w-sync_modal-container {
    z-index: 10000 !important;
}

.tag-list {
    border-radius: 15px;
    line-height: none !important;
}

/*
 * @component cualquiera
 * @description El puntero cambia al hacer hover sobre un elemente
 */
.hover:hover {
    cursor: pointer;
}

.hover-effect:hover{
    cursor: pointer;
    background-color: #f2f2f2 !important;
}

/*
* Clases globales que se pueden implementar en cualquier parte del sistema
* Son para mover elementos a una cierta posicion o aplicar espacios
*/


.text-wrap{
    white-space: pre-wrap;
    max-height: 57px;
    overflow-y: hidden;
    text-overflow: ellipsis;
}
.d-block {
    display: block !important;
}

.border {
    border: solid 1px red !important;
}

.no-border {
    border: none !important;
}

.no-strong {
    font-weight: 300 !important;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.block-center {
    display: block;
    margin: auto;
}

.block {
    display: block;
}

.flex {
    display: flex;
}

.flex-column {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
}

.flex-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.flex-left-column {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.flex-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.flex-right-top {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}

.flex-column-between {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.flex-left-column-between {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
}

.flex-left-column-around {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-direction: column;
}

.flex-column-center{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ant-btn-primary-lime,
.ant-btn-primary-lime:hover,
.ant-btn-primary-lime:focus{
    border:  none;
    background: #02CC16;
}

.ant-btn-primary-lime *{
    color:  white !important;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.heigth-100 {
    height: 100% !important;
}

.p-0 {
    padding: 0 !important;
}


.pd-1, .p-1 {
    padding: 1rem !important;
}

.pt-1 {
    padding-top: 1rem !important;
}

.pt-05 {
    padding-top: 0.5rem !important;
}

.pt-2 {
    padding-top: 2rem !important;
}

.pl-1 {
    padding-left: 1.5rem !important;
}

.pl-2 {
    padding-left: 2rem !important;
}

.pl-3 {
    padding-left: 3rem !important;
}

.pr-1 {
    padding-right: 1rem !important;
}

.pr-2 {
    padding-right: 2rem !important;
}

.pb-1 {
    padding-bottom: 1rem !important;
}

.pb-2 {
    padding-bottom: 2rem !important;
}

.pb-3 {
    padding-bottom: 3rem !important;
}

.m-0 {
    margin: 0 !important;
}

.m-05 {
    margin: 0.5rem !important;
}

.ml-1 {
    margin-left: 1rem !important;
}

.ml-2 {
    margin-left: 2rem !important;
}

.ml-3 {
    margin-left: 3rem !important;
}

.mt-05{
    margin-top: 0.5rem !important;

}
.mt-1 {
    margin-top: 1rem !important;
}

.mt-2 {
    margin-top: 2rem !important;
}

.mt-3 {
    margin-top: 3rem !important;
}

.mb-05 {
    margin-bottom: 0.5rem !important;
}

.mr-05 {
    margin-right: 0.5rem !important;
}

.mb-1 {
    margin-bottom: 1rem !important;
}

.mb-2 {
    margin-bottom: 2rem !important;
}

.mb-3 {
    margin-bottom: 3rem !important;
}

.mr-1 {
    margin-right: 1rem !important;
}

.mr-2 {
    margin-right: 2rem !important;
}

.text-center,
.text-center .ant-form-item-label {
    text-align: center !important;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-white {
    color: #FFF !important;
}

.text-gray {
    color: #858997 !important;
}
.text-legal{
    color:  #222222;
}

.text-gray-dark {
    color: #222222 !important;
}

.text-blue{
    color: #0022A9;
}

.text-purple {
    color: #5C54E0 !important;
}

.font-18 {
    font-size: 18px !important;
}

.width-100,
.width-100 .ant-upload {
    width: 100%;
}


.width-95 {
    width: 95%;
}

.bg-white {
    background: #FFFFFF;
}


.disabled-link {
    pointer-events: none;
}




/*
 * @component Listas para el despliege de registros generales
 * @description pequeño linea de color que se encuentra en el lado izquierdo en cada elemento de la lista
 */
.component-list .card-list .badge-card {
    width: 6px;
    height: 45px;
    position: absolute;
    left: -2px;
    border-radius: 2px;
}


/*
 * @component Listas para el despliege de registros generales
 * @description color de la linea de la lista de transacciones, depentiendo del tipo de transaccion
 */
.component-list .card-list .badge-card.tipo-4 {
    background: gray;

}

/*
 * @component Tamaño de la barra de navegación
 * @description Es el tamaño universal de la barra de navegación
 */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

/*
 * @component Tamaño de la barra de navegación (area de mobilidad)
 * @description Se refiere al area donde el scroll se mueve
 */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #BBBBBB;
    border-radius: 20px;
}

/*
 * @component La bara de navegación en si, pero el desalizador
 * @description CSS del deslizador de la barra de navegación
 */
::-webkit-scrollbar-thumb {
    background: #2d338ca8;
    border-radius: 20px;
}

/*
 * @component La barra de navgeación, el thumb al hacer hover
 * @description CSS del deslizador pero al pasar el mouse
 */
::-webkit-scrollbar-thumb:hover {
    background: #2d338c;
}

.component-table .ant-table-container, .component-table .ant-table-header, .component-table .ant-table {
    min-width: 2500px;
}

.component-table {
    overflow-x: auto;
}