.dashboard {
    .vencidas{
        background: linear-gradient( 126.63deg, #F06363 11.98%, #FF0000 83.35%);
        border-radius: 10px;
    }
    
    .por-vencer{
        background: linear-gradient( 126.63deg, #ECD769 11.98%, #E4E909 83.35%);
        border-radius: 10px;    
    }
    
    .a-tiempo{
        background: linear-gradient( 126.63deg, #5EFF9E 11.98%, #65FC4D 83.35%);
        border-radius: 10px;    
    }
    
    .card-title{
        color: #FFFFFF;
        font-size: 24px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0px;
    
    }

    .nombre-proveedor{
        font-size: 25px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0px;

    }

    .tag-title{
        color: #858997;
    }

    .tag-info{
    font-size: 12px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #858997;

    }

    .tag-estatus{
        background-color: #236FFF;
        border-radius: 90px;
        border: unset !important;
    }

    .tag-estatus-txt{
        color: #FFFFFF;
    }

    .concepto{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;

    }

    .card-list{
        border-radius: 10px;
        
    }
}