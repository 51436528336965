@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&family=Roboto:wght@100;300;400;500;700&display=swap');


/*
 * @component Login
 * @description titulo del formulario
 */
.h3-title {
    /* font-family: Roboto; */
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 48px;
    color: #233B58;
}
/*
 * @component Login
 * @description inputs del formulario
 */
.input-login {
    height: 55px;
    background: #E1E4ED;
    border-radius: 5px!important;
}
/*
 * @component Login
 * @description boton del formulario
 */
.button-login {
    height: 55px!important;
    border-radius: 5px!important;
    /* font-family: Roboto; */
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 5px;
    color: #FFFFFF;
    background: #2D348C!important;
}