@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap');



/*
 * @component Landing -> Content
 * @description Ajusta el height del content para que abarque toda la pantalla
 */
.landing-content{
    width: 100%;
    height: calc(100vh - 100px);
}
/*
 * @component Landing -> Content
 * @description Ajusta el contenido al centro de la pantalla
 */
.center-landing{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    /*padding: 12% 0px;*/
}

/*
 * @component Landing -> Button
 * @description Estilos del boton principal de la pagina
 */
.landing-button{
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    height: 60px!important;
    background: #0022A9!important;
    text-transform: uppercase;
    /* font-family: Lato,sans-serif; */
    font-style: normal;
    font-weight: bold;
    display: block!important;
    margin: auto;
    font-size: 19px;
    line-height: 25px;
    border-radius: 0px!important;
    letter-spacing: 5px;
    border: 1px solid transparent!important;
    color: #FFFFFF!important;
   


}

/*
 * @component Landing -> Button
 * @description Estilos del boton principal de la pagina al hacer hover
 */
.landing-button:hover,
.landing-button:focus,
.landing-button:active{
    background: #fff!important;
    color: #2D348C!important;
    text-transform: uppercase;
    animation: fadeIn linear 5s;
}

@-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }