/*
 * @component ModalAgregarProducto
 * @description Estilos del modal para agregar productos
 */
 .agregar-producto *{
    color: white !important;
 }


 .agregar-producto .ant-modal-body{
    background-color: #233B58 !important;
}
.agregar-producto .ant-form-item-label label, .agregar-producto h2{
    color: white !important;
}
.agregar-producto .anticon-close svg {
    fill: white;
}
.agregar-producto .ant-modal-close{
    display: none !important;
}





/*
 * @component Marketplace -> Input y InputNumber 
 * @description Estilos para el formulario
 */

.agregar-producto .ant-input, .agregar-producto .ant-input-number-input{
    background-color: rgba(255, 255, 255, 0.2) !important;
    color: white !important;
}

.agregar-producto .ant-input-number-input{
    padding: 8px 11px !important;
}

.agregar-producto .ant-input-number:not(.ant-input-number-status-error){
    border: none !important;
}

.agregar-producto .ant-input-number{
    width: 100% !important;
    background-color: transparent !important;
}

.agregar-producto .ant-input::placeholder, .agregar-producto .ant-input-number *::placeholder{
    color: rgba(255, 255, 255, 0.5);
}
.agregar-producto-button{
    background-color: #E05447 !important;
    border: none !important;
    border-radius: 2rem !important;
    color: white !important;
    font-weight: 700 !important;
    padding: 0.5rem 3rem !important;
}

.agregar-producto-col-input .ant-input-disabled{
    background-color: rgba(255, 255, 255, 0.1) !important;
}
.agregar-producto-col-input .ant-form-item-extra{
    color: rgba(255, 255, 255, 0.8) !important;
}

/*
 * @component Marketplace -> Upload 
 * @description Estilos para el cuadro para subir fotos
 */

.agregar-producto .ant-upload.ant-upload-select.ant-upload-select-picture-agregar{
    background-color: transparent;
    border: 1px solid white !important;
}

.agregar-producto .ant-upload.ant-upload-select.ant-upload-select-picture-agregar:hover{
    border-color: rgba(255, 255, 255, 0.2) !important;
}

.agregar-producto .ant-upload * {
    color: white !important;
}
.agregar-producto .ant-upload.ant-upload-select-picture-card{
    background-color: transparent !important;
}

/*
 * @component Marketplace -> Form.Input 
 * @description Cambiar orientacion de los labels e inputs
 */
.agregar-producto-row-input{
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between;
    align-items: center;
}

.agregar-producto-row-input .ant-form-item-control-input-content{
    flex: none;
    width: 100%;
}

.agregar-producto-row-input .ant-form-item-control{
    flex: none;
    width: 60%;
}

.agregar-producto-form-item .ant-form-item-control-input{
    min-height: auto !important;
}

/*
 * @component Marketplace -> Radio.Group 
 * @description Estilos para hacer radio buttons de manera personalizada
 */
.agregar-producto-radio-group{
    display: flex !important;
    column-gap: 1.5rem !important;
    align-items: center !important;
}

.agregar-producto-radio-box{
    border: none !important;
    padding: 0 !important;
    background-color: transparent !important;
    line-height: 0 !important;
    height: auto !important;
}
.agregar-producto-radio-box:not(:first-child)::before{
    display: none !important;
}

.agregar-producto-radio-box.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within{
    box-shadow: none !important;
}

.agregar-producto-radio-icon{
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 0.25rem;
}

.agregar-producto-radio-icon-mark{
    width: 16px;
    height: 16px;
    background-color: white;
    border-radius: 0.125rem;
}

.agregar-producto-radio-box:not(.ant-radio-button-wrapper-checked) .agregar-producto-radio-icon-mark{  
    transform: scale(0);
    transition: transform .3s ease-in-out;
}

.agregar-producto-radio-box.ant-radio-button-wrapper-checked .agregar-producto-radio-icon-mark{  
    transform: scale(1);
    transition: transform .3s ease-in-out;
}

.agregar-producto-radio-label{
    color: white !important;
    margin-bottom: 0 !important;
}

.agregar-producto-required .ant-form-item-label > label::before{
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: sans-serif;
    line-height: 1;
    content: '*';
}