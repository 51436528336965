/*
 * @component Header 
 * @description Cambia el tamaño del header
 */
.header {
    height: auto;
    min-height: 100px;
    padding: 0px!important;
}

/*
 * @component Header 
 * @description Cambia el tamaño del contenedor de la imagen (logo) del header
 */
.logo {
    height: 100px;
    min-height: 100px;
}


/*
 * @component Header 
 * @description Cambia el tamaño del logo del header
 */
 .logo img {
    height: 50px!important;
    margin: 1.5rem auto ;
    display: block !important;
}

/*
 * @component Header 
 * @description Cambia el tamaño del contenedor de la imagen para que se ajuste al sidebar
 * Si se mueve este estilo, se tiene que modificar tambien el del sider-width
 */
.header-logo {

    max-width: 240px!important;
    width: calc(100vw - ( 100vw * 0.78))!important;
}

/*
 * @component Header 
 * @description Cambia estilo del link para hacer login (Iniciar Sesion)
 */
.link-login {
    // font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: #FFFFFF;
    background: transparent;
    position: absolute;
    right: 10px;
    top: 15px;
}


/*
 * @component Header 
 * @description Estilos del link (iniciar sesion) al hacer hover
 */
.link-login:hover {
    color: #bababa;
    animation: fadeIn linear 5s;
}


/*
 * @component Header -> InputSearch
 * @description Estilos de la barra de busqueda del header
 */
.header-search {
    display: flex;
    margin: 24px 10px;
    max-width: 437px;
}


.custom-page-header{
    min-height: 72px;
    display: flex;
    align-items: center;
}
/*
 * @component PAgeHeader 
 * @description Hace que el contenedor del title del pageheader abarque todo el espacio
 */
 .custom-page-header .ant-page-header-heading, .custom-page-header .ant-page-header-heading .ant-page-header-heading-left, .custom-page-header .ant-page-header-heading .ant-page-header-heading-left .ant-page-header-heading-title {
    width: 100%!important;
}
/*
 * @component PAgeHeader -> extra 
 * @description Para el contenido en extra que sea texto plano
 */
.custom-page-header .page-header-extra{
    color: #FFF;
    font-size: 11pt;
    font-weight: 600;
    line-height: 1.5;
   vertical-align: sub;
}
/*
 * @component Header -> Menu 
 * @description Alinea los elementos del contenido del header despues del logo
 */
.header-content {
    align-items: center;
    justify-content: space-between;
    background-color: #FFF;
    min-height: 50px;
}

/*
 * @component Header -> Menu 
 * @description Alinea los divs del contenedor de las opciones de usuario (notificaciones/logout)
 */
.header-options div {
    display: inline-block;
    margin: 5px 0;
    vertical-align: middle;
}


/*
 * @component Header -> Menu 
 * @description cambia el estilo del icono de notificaciones
 */
.header-options .icon-bell {
    color: #cccccc;
    font-size: 17pt;
    margin-bottom: -7PX;
}
/*
 * @component Header -> Menu 
 * @description cambia el estilo del bagde de notificaciones
 */
.header-options .ant-badge-count {
    background-color: #5e45d7;
}


/*
 * @component Header -> InputSearch 
 * @description cambia el estilo input de busqueda del header 
 */
.input-global-search .ant-input-group * {
    height: 50px!important;
}


/*
 * @component Header -> InputSearch 
 * @description cambia el estilo button de busqueda del header
 */
.input-global-search .ant-input-group .ant-input-group-addon button {
    background: #D2D4E7;
    border: none;
    margin-left: -7px;
    width: 50px;
    border-radius: 8px !important;
}


/*
 * @component Header -> InputSearch 
 * @description cambia el estilo icono del input de busqueda del header
 */
.input-global-search .ant-input-group .ant-input-group-addon button .anticon svg {
    color: #0022A9;
}

/*
 * @component Header   
 * @description Estilos del header para pantallas pequeñas
 */
@media (max-width: 480px) {
    .header-logo {
        background: #0022A9 !important;
        width: 100%;
        height: auto;
        position: relative;
    }
    .header-logo .logo {
        display: block;
        margin: auto;
    }
    .header-content {
        display: flex;
        flex-flow: column;
        align-items: flex-end;
        height: auto !important;
        padding-top: 1rem;
    }
    .header-content .header-search {
        order: 2;
        width: 100%;
        padding: 1rem 2.5rem 1rem 0;
    }
    .header-content .header-options {
        order: 1;
    }
}

/*
 * @component Header   
 * @description Estilos del header para pantallas medianas
 */
@media (max-width: 768px) {
    .header {
        display: contents !important;
        line-height: 1rem !important;
    }
    .header-logo {
        background: #0022A9;
    }
    .header .row-header-content {
        width: 100%;
        background: #0022A9;
    }
}

/*
 * @component Header   
 * @description Estilos del header para pantallas de medianas a largas
 */
@media (max-width: 1200px) and (min-width: 768px) {
    .header .header-logo .logo {
        min-width: auto;
        transition-duration: 1s !important;
    }
}