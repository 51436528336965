.radio-cotizaciones .ant-radio-button-wrapper:first-child,
.radio-cotizaciones .ant-radio-button-wrapper{
	border-radius: 10px !important;
	margin-left: 4px;
	border:  none;
}

.radio-cotizaciones .ant-radio-button-wrapper::before{
	content: none !important;
}

.radio-cotizaciones.ant-radio-group-solid .ant-radio-button-wrapper-checked,
.radio-cotizaciones .ant-radio-button-checked{
	background: #e9ecf1 !important;
	border-radius: 10px !important;
	border:  none;
}

.radio-cotizaciones .ant-radio-button-wrapper.ant-radio-button-wrapper-checked span{
	color: #0022a9 !important;
	border: none;
	font-weight: 600;
}


.tag-card-cotizacion{
	color: #0089ED !important;
	background: #cce7fb !important;
	opacity: 0.2;
	border-radius: 200px !important;
	border:  none !important;
	min-width: 200px;
	text-align: center;
	font-weight: 600;
}


.btn-opc-circle{
	padding: 0px !important;
	height: 32px !important;
	width: 32px !important;
	border: none !important;
	border-radius: 50px !important;
}

.btn-opc-circle.aceptar{
	color: #3BE13B;
}
.btn-opc-circle.recotizar{
	color: #FAFF0A;
}
.btn-opc-circle.rechazar{
	color: #DA4252;
}


.tabs-inventario .ant-tabs-nav-list{
    background: white;
    padding: 0.8rem;
    border-radius: 10px;
}

.tabs-inventario .ant-tabs-tab-active{
    background: #deddf9 !important;
    color: #5C54E0 !important;
    border: #FFFFFF;
    border-radius: 30px;
    padding: 0 10px;
    width: 100px;
    text-align: center;
    justify-content: center;
    margin: 0 !important;
}
.tabs-inventario .ant-tabs-tab{
    width: 100px;
    margin: 0 !important;
    justify-content: center;
    text-align: center;
}

.tabs-inventario .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: #5C54E0 !important;
}

.tabs-inventario .ant-tabs-ink-bar{
    display: none;
}


