/* SIDER MENU */

@media (min-width: 992px){
    .sider-width{
        min-width: 240px ;
    }
}


/*
 * @component Sidebar 
 * @description Ajusta estilo del titulo de cada grupo de elementos del menu
 */
.ant-menu-item-group-title {
    /* font-family: Lato; */
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    text-transform: uppercase;
    line-height: 13px;
    letter-spacing: 3px;
    color: #C4C4C4;
}
/*
 * @component Sidebar 
 * @description Ajusta el tamaño de la fuente de todo el sider
 */
.custom-sider * {
    font-size: 0.9rem;
}

.custom-sider li span.ant-menu-title-content{
    margin-left: 0px;
}
/*
 * @component Sidebar 
 * @description Ajusta estilo de los iconos de todo el sider
 */
.custom-sider svg {
    font-size: 1.25rem!important;
}

/*
 * @component Sidebar 
 * @description Ajusta estilo de los iconos de todo el sider
 */
.custom-sider .ant-layout-sider-children ul.ant-menu li:first-child{
    margin-top:0.5rem;
}

/*
 * @component Sidebar  ->MenuDivider
 * @description Añade un espacio entre grupos de elementos del menu del sidebar
 */
.group-sider-divider {
    margin: 2rem 0px;
}
/*
 * @component Sidebar 
 * @description Cambia el color de los links del menu.item del sidebar
 */
.custom-sider ul li.ant-menu-item, .custom-sider ul li.ant-menu-item a,
.custom-sider ul li .anticon{
    color: #4D4D4D;
}


/*
 * @component Sidebar 
 * @description Estilos de fuente de cada titulo del menu
 */
.custom-sider ul li.ant-menu-item, .custom-sider ul div.ant-menu-submenu-title {

    /* font-family: Lato; */
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 2px;
    color: #4D4D4D!important;
}


/*
 * @component Sidebar -> Menu
 * @description Cambia la posicion del borde del elemento seleccionado
 */
.custom-sider ul li.ant-menu-item::after {
    left: 0;
    border-left: 5px solid #2D348C;
    border-right: none!important;
}

/*
 * @component Sidebar -> Menu
 * @description Ajusta el margen de los iconos del titulo de cada menu.item
 */
.custom-sider ul li.ant-menu-item svg, .custom-sider ul div.ant-menu-submenu-title svg {
    margin-right: 15px;
}

/*
 * @component Sidebar -> Menu
 * @description Cambia el color del menu.item seleccionado tanto el icono como el texto
 */
.custom-sider ul li.ant-menu-item:hover *, .custom-sider ul li.ant-menu-item:hover a {
    color: #2D348C;
}


.btn-display .ant-layout-sider-zero-width-trigger {
    display: block;
    transition-delay: 10ms;
}

.btn-no-display .ant-layout-sider-zero-width-trigger-left {
    display: none;
    transition-delay: 10ms;
}