.filter-radio{
    width: fit-content;
}
.filter-radio .ant-radio-button-wrapper{
    border-radius: 20px!important;
    border: none;
    border-color: #FFFFFF!important;

}

.filter-radio .ant-radio-button-wrapper:not(:first-child)::before{
    width: 0;
}

.filter-radio  .ant-radio-button-wrapper-checked{
    background: #0022a9 !important;
    color: #5C54E0!important;
    border: #FFFFFF;
}