.dashboard-mantenimiento-tabs{
    & .ant-tabs-tab:nth-child(1) .ant-tabs-tab-btn, & .ant-tabs-tab:nth-child(1) .anticon{
        color: #FF0000;
    }

    & .ant-tabs-tab:nth-child(1).ant-tabs-tab-active ~ .ant-tabs-ink-bar{
        background-color: #FF0000;
    }

    & .ant-tabs-tab:nth-child(2) .ant-tabs-tab-btn, & .ant-tabs-tab:nth-child(2) .anticon{
        color: #E2E200;
    }

    & .ant-tabs-tab:nth-child(2).ant-tabs-tab-active ~ .ant-tabs-ink-bar{
        background-color: #E2E200;
    }
}

.dashboard-mantenimiento-tasks{
    flex-wrap: nowrap !important;
    overflow-x: auto;
    margin-bottom: 2rem;
}

.dashboard-mantenimiento-col.ant-col{
    min-width: 350px;
}

@media screen and (min-width: 1600px) {
    .dashboard-mantenimiento-col.ant-col{
        display: block;
        flex: 0 0 20%;
        max-width: 20%;
    }
}

