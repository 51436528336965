.nacional-table {

    .ant-table-thead{
        .ant-table-cell{
            text-align: center;
            background-color: #ffffff;
        }
    }

    .form-item-matriz-nacional{
        margin-bottom: 0px;
        
        .ant-input-affix-wrapper{
            background-color: #0022A9;
            color: #ffffff !important;
        }
        .ant-input{
            color: #ffffff !important;
            text-align: center;
            background-color: #0022A9;
        }
        .ant-input::placeholder{
            color: #ffffff !important;
            text-align: center;
            background-color: #0022A9;
        }
        .ant-form-item{
            color: #ffffff !important;
        }
    }
    
}

.matriz-nacional-label{
    background: #0022A9 !important;
    border: unset !important;
    color: #ffffff !important;
    height: 38px !important;

    .ant-typography{
        color: #ffffff !important;
    }

}

.matriz-nacional-data{
    background: #E1E4ED !important;
    border: unset !important;
    height: 38px !important;
    line-height: 100%;

    .ant-form-item{
        margin: 0 !important;
    }
}

.ant-form-item{
    //margin: 0% !important;
}