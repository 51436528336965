body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

iframe {
  display: none;
}

.test-banner{
  z-index: 100;
  opacity: 0.4;
  position: sticky;
  bottom: 0px;
  width: 100%;
  text-align: center;
  background: #0022A9 !important;
  box-shadow: 0 -.3rem 1rem rgba(0,0,0,.15)!important;
  transition: all 0.2s ease;
  cursor: default;
}

.test-banner:hover {
  opacity: 1;
}

.test-banner .test-banner-text {
  display: block;
  margin:  0 auto;
  padding: 0.3em 0;
  color: white;
  font-size: 16px;
}

.test-banner.sticky {
  opacity: 1 !important;
}


@media (max-width: 576px) { 
  .test-banner .test-banner-text {
    font-size: 10px;
  } 
}
@media (max-width: 768px) { 
  .test-banner .test-banner-text {
    font-size: 12px;
  } 
}
@media (max-width: 992px) {
  .test-banner .test-banner-text {
    font-size: 14px;
  }
}